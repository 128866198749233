
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDlIEz06YVYxmNhDuCFx1Vqf7Rz3tK_b-Q",
    authDomain: "login-webapp-2024.firebaseapp.com",
    projectId: "login-webapp-2024",
    storageBucket: "login-webapp-2024.appspot.com",
    messagingSenderId: "808443146933",
    appId: "1:808443146933:web:2220fdb15451941a1c84fc",
    measurementId: "G-4QQBE6BJX5"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export default app;
