import React from 'react';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase-config';
import './HomePage.css'; // Import the CSS file

function HomePage() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/');
  };

  return (
    <div>
      <nav className="top-menu">
        <div className="menu-items">
          <a href="#research-paper">Research Paper</a>
          <a href="#undergrad-thesis">UnderGrad Thesis</a>
          <a href="#masters-sop">Master's SOP</a>
        </div>
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </nav>

      <div id="research-paper" className="pdf-holder">
        <h2>Research Paper</h2>
        <iframe title="a" src="/a.pdf#zoom=110" className="pdf-iframe"></iframe>
      </div>
      <div id="undergrad-thesis" className="pdf-holder">
        <h2>UnderGrad Thesis</h2>
        <iframe title="b" src="/b.pdf#zoom=110" className="pdf-iframe"></iframe>
      </div>
      <div id="masters-sop" className="pdf-holder">
        <h2>Master's SOP</h2>
        <iframe title="c" src="/c.pdf#zoom=110" className="pdf-iframe"></iframe>
      </div>
    </div>
  );
}

export default HomePage;
