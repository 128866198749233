import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase-config';

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [imageIndex, setImageIndex] = useState(0);

  const images = [
    '/Hyd1.webp',
    'acadia.webp',
    'vil1.webp',
    'Seq1.webp',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex(prevIndex => (prevIndex + 1) % images.length); // Cycle through the images
    }, 6000);
    return () => clearInterval(interval);
  }, [images.length]);

  const loginPageStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundImage: `url(${images[imageIndex]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };

  
  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home');
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/home');
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div style={loginPageStyle}>
      <div style={formStyle}>
        <h2 style={titleStyle}>Login to Nikhil's Life</h2>
        <input 
          type="email" 
          placeholder="Email Address" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          style={inputStyle} 
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          style={inputStyle} 
        />
        <button onClick={handleLogin} style={buttonStyle}>Login</button>
        <p style={separatorStyle}>OR</p>
        <button onClick={handleGoogleSignIn} style={buttonStyle}>Login with Google</button>
      </div>
    </div>
  );
}

// Styling


const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '300px',
  backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
};

const titleStyle = {
  textAlign: 'center',
  marginBottom: '20px'
};

const inputStyle = {
  marginBottom: '15px',
  padding: '15px',
  fontSize: '16px',
  borderRadius: '5px',
  border: '1px solid #ddd'
};

const buttonStyle = {
  padding: '15px 20px',
  marginBottom: '10px',
  cursor: 'pointer',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#2c98f0',
  color: 'white',
  fontSize: '16px'
};

const separatorStyle = {
  textAlign: 'center',
  margin: '20px 0'
};

export default LoginPage;
